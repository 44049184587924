import { Injectable } from '@angular/core';
import {
  LimitReportDynamicLimit,
  LimitReportDynamicLimitAtRisk,
  LimitReportEnergySourcePolicy,
  LimitReportReviewBenchmark,
  LimitReportReviewIndexChart,
  LimitReportReviewPassiveStrategy,
  LimitReportReviewPosition,
  LimitReportReviewSettings,
} from '@backoffice-frontend/limit-report/domain';
import { LimitReportReviewTechnicalIndicatorChart } from '@backoffice-frontend/limit-report/graphs/ui-technical-indicators-graphs';
import { ID } from '@clean-code/shared/common';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LimitReportReviewSettingsPowerService {
  private url = this.configService.settings['api'] + 'limitreportpower/graphql';

  public readonly entityFields = `
    showPositions
    showIndex
    showPurchase
    showClosedPositionsAssessment
    showBenchmark
    showDynamicLimit
    showDynamicVaR
    showTecIndOverallStrategy
    showTecIndTrend
    showTecIndSideWay
    showTecIndTrendType
    showTecIndSideWayType
    showEnergySourcingPolicy
    showPassiveStrategy
    unit
    name
    nameSpread
    nameHkn
    companyName
    limitReportName

    showSpreadPositions
    showSpreadIndex
    showSpreadBenchmark
    showHknPositions
    showHknIndex
    showHknBenchmark

    dynamicLimitSignalGenerationMode
    dynamicValueAtRiskSignalGenerationMode
    technicalIndicatorSignalGenerationMode

    dynamicLimitSignalGenerationModeSpread
    dynamicValueAtRiskSignalGenerationModeSpread

    dynamicLimitSignalGenerationModeHkn
    dynamicValueAtRiskSignalGenerationModeHkn

    showDynamicLimitSpread
    showDynamicVaRSpread
    showPassiveStrategySpread
  
    showDynamicLimitHkn
    showDynamicVaRHkn
    showPassiveStrategyHkn
  `;

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public limitReportReviewShowSettings$(
    id: ID
  ): Observable<LimitReportReviewSettings> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewShowSettings(id:$id) {
          ${this.entityFields}
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewSettings>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewClosedPositions$(
    id: ID
  ): Observable<LimitReportReviewPosition> {
    const query = `
      query ($id:ID!)
      {
        limitReportReviewClosedPositions(id:$id) {
          closedPositionReview
          {
            positions
            {
              contingent
              orderDate
              tradeDate
              shareInPercent
              amount
              price
              priceBase
              pricePeak
              strategy
            }
            shareSum
            amountSum
            priceAverage
          }
          closedPositionEvaluatedReview
          {
            positions
            {
              strategy
              amountPositions
              amount
              shareInPercent
              price
              fullPrice
              benchmark
              differencePrice
              differencePercent
            }
            amountPositions
            amount
            shareInPercent
            price
            fullPrice
            benchmark
            differencePrice
            differencePercent
          }
          openPositionsReview
          {
            openPurchases
            shareInPercent
            amount
            currentMarketPrice
            completePrice
          }
          allPositionsReview
          {
            shareInPercent
            amount
            currentMarketPrice
            fullPrice
          }
        }
      }
    `;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPosition>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewClosedSpreadPositions$(
    id: ID
  ): Observable<LimitReportReviewPosition> {
    const query = `
      query ($id:ID!)
      {
        limitReportReviewClosedSpreadPositions(id:$id) {
          closedPositionReview
          {
            positions
            {
              contingent
              orderDate
              tradeDate
              shareInPercent
              amount
              price
              priceBase
              pricePeak
              strategy
            }
            shareSum
            amountSum
            priceAverage
          }
          closedPositionEvaluatedReview
          {
            positions
            {
              strategy
              amountPositions
              amount
              shareInPercent
              price
              fullPrice
              benchmark
              differencePrice
              differencePercent
            }
            amountPositions
            amount
            shareInPercent
            price
            fullPrice
            benchmark
            differencePrice
            differencePercent
          }
          openPositionsReview
          {
            openPurchases
            shareInPercent
            amount
            currentMarketPrice
            completePrice
          }
          allPositionsReview
          {
            shareInPercent
            amount
            currentMarketPrice
            fullPrice
          }
        }
      }
    `;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPosition>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewClosedHknPositions$(
    id: ID
  ): Observable<LimitReportReviewPosition> {
    const query = `
      query ($id:ID!)
      {
        limitReportReviewClosedHknPositions(id:$id) {
          closedPositionReview
          {
            positions
            {
              contingent
              orderDate
              tradeDate
              shareInPercent
              amount
              price
              priceBase
              pricePeak
              strategy
            }
            shareSum
            amountSum
            priceAverage
          }
          closedPositionEvaluatedReview
          {
            positions
            {
              strategy
              amountPositions
              amount
              shareInPercent
              price
              fullPrice
              benchmark
              differencePrice
              differencePercent
              groupedName
            }
            amountPositions
            amount
            shareInPercent
            price
            fullPrice
            benchmark
            differencePrice
            differencePercent
          }
          openPositionsReview
          {
            openPurchases
            shareInPercent
            amount
            currentMarketPrice
            completePrice
          }
          allPositionsReview
          {
            shareInPercent
            amount
            currentMarketPrice
            fullPrice
          }
        }
      }
    `;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPosition>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewIndexChart$(
    id: ID
  ): Observable<LimitReportReviewIndexChart> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewIndexChart(id:$id) {
        title
        indexTable
        {
          current
          month1
          month6
          year1
          year2
        }
        limitTable
        {
          upperLimit
          lowerLimit
          utilization
          limitViolation
        }
        amountTable
        {
          purchases
          openPositionsInPercent
          closedPositionsInPercent
          totalAmount
        }
        overallAssessmentTable
        {
          contractAmount
          terminAmount
          spotAmount
        }
        dataPoints
        {
          date
          price
          close
          low
          high
          orderedAmountInPercent
          volume
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewIndexChart>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewSpreadIndexChart$(
    id: ID
  ): Observable<LimitReportReviewIndexChart> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewSpreadIndexChart(id:$id) {
        title
        indexTable
        {
          current
          month1
          month6
          year1
          year2
        }
        limitTable
        {
          upperLimit
          lowerLimit
          utilization
          limitViolation
        }
        amountTable
        {
          purchases
          openPositionsInPercent
          closedPositionsInPercent
          totalAmount
        }
        overallAssessmentTable
        {
          contractAmount
          terminAmount
          spotAmount
        }
        dataPoints
        {
          date
          price
          close
          low
          high
          orderedAmountInPercent
          volume
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewIndexChart>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewHknIndexChart$(
    id: ID
  ): Observable<LimitReportReviewIndexChart> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewHknIndexChart(id:$id) {
        title
        indexTable
        {
          current
          month1
          month6
          year1
          year2
        }
        limitTable
        {
          upperLimit
          lowerLimit
          utilization
          limitViolation
        }
        amountTable
        {
          purchases
          openPositionsInPercent
          closedPositionsInPercent
          totalAmount
        }
        overallAssessmentTable
        {
          contractAmount
          terminAmount
          spotAmount
        }
        dataPoints
        {
          date
          price
          close
          low
          high
          orderedAmountInPercent
          volume
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewIndexChart>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewBenchmarkChart$(
    id: ID
  ): Observable<LimitReportReviewBenchmark> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewBenchmarkChart(id:$id) {
        benchmarkTable
        {
          indexName
          current
          mtM
          benchmark
          mtMBM
          review
          year1
          stdDev
        }
        dataPoints
        {
          date
          indexPrice
          benchmarkPrice
          mtMValue
          mtMClosedPositionValue
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewBenchmark>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewSpreadBenchmarkChart$(
    id: ID
  ): Observable<LimitReportReviewBenchmark> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewSpreadBenchmarkChart(id:$id) {
        benchmarkTable
        {
          indexName
          current
          mtM
          benchmark
          mtMBM
          review
          year1
          stdDev
        }
        dataPoints
        {
          date
          indexPrice
          benchmarkPrice
          mtMValue
          mtMClosedPositionValue
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewBenchmark>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewHknBenchmarkChart$(
    id: ID
  ): Observable<LimitReportReviewBenchmark> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewHknBenchmarkChart(id:$id) {
        benchmarkTable
        {
          indexName
          current
          mtM
          benchmark
          mtMBM
          review
          year1
          stdDev
        }
        dataPoints
        {
          date
          indexPrice
          benchmarkPrice
          mtMValue
          mtMClosedPositionValue
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewBenchmark>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicLimitChart$(
    id: ID
  ): Observable<LimitReportDynamicLimit> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicLimitChart(id:$id) {
        dynamicLimitTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          lmin
          lmax
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimit>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicLimitSpreadChart$(
    id: ID
  ): Observable<LimitReportDynamicLimit> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicLimitSpreadChart(id:$id) {
        dynamicLimitTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          lmin
          lmax
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimit>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicLimitHknChart$(
    id: ID
  ): Observable<LimitReportDynamicLimit> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicLimitHknChart(id:$id) {
        dynamicLimitTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          lmin
          lmax
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimit>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicValueAtRiskChart$(
    id: ID
  ): Observable<LimitReportDynamicLimitAtRisk> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicValueAtRiskChart(id:$id) {
        dynamicValueAtRiskTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          weight
          confidence
          dFactor
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimitAtRisk>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicValueAtRiskSpreadChart$(
    id: ID
  ): Observable<LimitReportDynamicLimitAtRisk> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicValueAtRiskSpreadChart(id:$id) {
        dynamicValueAtRiskTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          weight
          confidence
          dFactor
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimitAtRisk>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewDynamicValueAtRiskHknChart$(
    id: ID
  ): Observable<LimitReportDynamicLimitAtRisk> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewDynamicValueAtRiskHknChart(id:$id) {
        dynamicValueAtRiskTable
        {
          indexName
          value
          current
          signal
          lastSignal
          avrSignal
          start
          months
          closedAmount
          openAmount
          weight
          confidence
          dFactor
        }
        dataPoints
        {
          date
          indexPrice
          dynamicLimit
          buySignal
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportDynamicLimitAtRisk>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewTechnicalIndicatorChart$(
    id: ID
  ): Observable<LimitReportReviewTechnicalIndicatorChart> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewTechnicalIndicatorChart(id:$id) {
        strategyViewModel
        {
          dataPoints
          {
            date
            indexPrice
            lowerLine
            upperLine
            buySignalTrend
            buySignalSideway
            aroonUp
            aroonDown
          }
          table
          {
            averageSignal
            lastSignal
            lastSignalType
            aroonUp
            aroonDown
            aroonTrend
            trend
            sidewayTrend
          }
        }
        macdViewModel
        {
          dataPoints
          {
            date
            macd
            macdTrigger
            macdHist
            buySignal
            indexPrice
          }
          table
          {
            avgSignal
            lastSignal
            macd
            macdTrigger
            signal
            fast
            slow
            period
          }
        }
        rsiViewModel
        {
          dataPoints
          {
            date
            buySignal
            indexPrice
            rsi
            channelHigh
            channelLow
          }
          table
          {
            avgSignal
            lastSignal
            rsi
            signal
            upperBound
            lowerBound
            period
          }
        }
        cciViewModel
        {
          dataPoints
          {
            date
            cci
            channelLow
            channelHigh
            buySignal
            indexPrice
          }
          table
          {
            avgSignal
            lastSignal
            cci
            signal
            upperBound
            lowerBound
            period
          }
        }
        stochViewModel
        {
          dataPoints
          {
            date
            buySignal
            indexPrice
            slowK
            slowD
            channelHigh
            channelLow
          }
          table
          {
            avgSignal
            lastSignal
            currentSlowK
            signal
            slowK
            fastK
            slowD
            upperBound
            lowerBound
            currentSlowD
          }
        }
        kstViewModel
        {
          dataPoints
          {
            date
            kst
            kstFiveMa
            buySignal
            indexPrice
          }
          table
          {
            avgSignal
            lastSignal
            currentKst
            signal
            w_1_4
            ma_1_4
            roc_1_4
            currentMa
          }
        }
        macViewModel
        {
          dataPoints
          {
            date
            mac
            buySignal
            indexPrice
          }
          table
          {
            avgSignal
            lastSignal
            currentMac
            signal
            period
            barrier
          }
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewTechnicalIndicatorChart>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewEnergySourcingPolicyChart$(
    id: ID
  ): Observable<LimitReportEnergySourcePolicy> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewEnergySourcingPolicyChart(id:$id) {
        energySourcingPolicyTable
        {
          deliveryDate
          yearlyConsumption
          hedgingTarget
          toleranceBand
          averagePower
          timeHorizon
        }
        dataPoints
        {
          date
          day
          lowLimit
          neutralPath
          highLimit
          hedgedVolume
          click
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportEnergySourcePolicy>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewPassiveStrategyChart$(
    id: ID
  ): Observable<LimitReportReviewPassiveStrategy> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewPassiveStrategyChart(id:$id) {
        title
        dataPoints {
          date
          label
          percentage
          price
          indexPrice
          orderedAmountInMWh
          basePrice
          peakPrice
          purchases
          {
            closingTime
            price
          }
        }
        dataTable {
          timeRange
          orderedAmount
          orderedAmountPercentage
          totalAverageIndexPrice
          totalAverageBuyingPrice
          isActive
          lastTradeDate
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPassiveStrategy>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewSpreadPassiveStrategyChart$(
    id: ID
  ): Observable<LimitReportReviewPassiveStrategy> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewSpreadPassiveStrategyChart(id:$id) {
        title
        dataPoints {
          date
          label
          percentage
          price
          indexPrice
          orderedAmountInMWh
          deliveryAreaPrice
          marketAreaPrice
          deliveryAreaPeakPrice
          marketAreaPeakPrice
          purchases
          {
            closingTime
            price
            marketAreaPrice
            deliveryAreaPrice
            marketAreaPeakPrice
            deliveryAreaPeakPrice
          }
        }
        dataTable {
          timeRange
          orderedAmount
          orderedAmountPercentage
          totalAverageIndexPrice
          totalAverageBuyingPrice
          isActive
          lastTradeDate
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPassiveStrategy>(
      query,
      variables,
      this.url
    );
  }

  public limitReportReviewHknPassiveStrategyChart$(
    id: ID
  ): Observable<LimitReportReviewPassiveStrategy> {
    const query = `
    query ($id:ID!)
    {
      limitReportReviewHknPassiveStrategyChart(id:$id) {
        title
        dataPoints {
          date
          label
          percentage
          price
          indexPrice
          orderedAmountInMWh
          deliveryAreaPrice
          marketAreaPrice
          purchases
          {
            closingTime
            price
          }
        }
        dataTable {
          timeRange
          orderedAmount
          orderedAmountPercentage
          totalAverageIndexPrice
          totalAverageBuyingPrice
          isActive
          lastTradeDate
        }
      }
    }
`;

    const variables = { id };

    return this.graphqlService.query<LimitReportReviewPassiveStrategy>(
      query,
      variables,
      this.url
    );
  }
}

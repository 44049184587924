import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LimitReportReviewOverviewBaseComponent } from '@backoffice-frontend/limit-report/domain';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'backoffice-frontend-limit-report-review-power-overview',
  templateUrl: './limit-report-review-overview.component.html',
  styleUrls: ['./limit-report-review-overview.component.scss'],
  imports: [CommonModule, MatProgressBarModule, TranslocoModule],
})
export class LimitReportPowerReviewOverviewComponent extends LimitReportReviewOverviewBaseComponent {
  constructor() {
    super();
  }
}

@if (isLoading) {
  <mat-progress-bar class="progress" mode="indeterminate"></mat-progress-bar>
}

@if (data$ | async; as overview) {
  <div class="table-container flex flex-col">
    <div class="flex flex-col xl:flex-row pb-2 print:flex-row">
      <div class="closed-positions w-full xl:w-1/2 print:w-1/2 overflow-y-auto">
        <h2>
          {{ 'limit-reports.review.CLOSED_SPREAD_POSITIONS_TITLE' | transloco }}
        </h2>
        <table class="table-layout">
          <thead>
            <tr>
              <th>
                {{ 'limit-reports.review.fields.CONTINGENT' | transloco }}
              </th>
              <th>
                {{ 'limit-reports.review.fields.TRADE_DATE' | transloco }}
              </th>
              <!-- <th>
              {{ 'limit-reports.review.fields.ORDER_DATE' | transloco }}
            </th> -->
              <th>
                {{ 'limit-reports.review.fields.SHARE_IN_PERCENT' | transloco }}
              </th>
              <th>
                {{ 'limit-reports.review.fields.AMOUNT' | transloco }} [MWh]
              </th>
              <th>
                {{ 'limit-reports.review.fields.PRICE' | transloco }}
                [€/MWh]
              </th>
              <th>
                {{
                  'limit-reports.review.fields.PRICE_DELIVERY_AREA_BASE'
                    | transloco
                }}
                [€/MWh]
              </th>
              <th>
                {{
                  'limit-reports.review.fields.PRICE_MARKET_AREA_BASE'
                    | transloco
                }}
                [€/MWh]
              </th>
              <!-- <th>
            {{ 'limit-reports.review.fields.STRATEGY' | transloco }}
          </th> -->
            </tr>
          </thead>
          <tbody>
            @for (
              position of overview.closedPositionReview.positions;
              track position
            ) {
              <tr>
                <td class="!font-normal">
                  {{ position.contingent }}
                </td>
                <!-- <td>{{ position.orderDate | date: 'shortDate' }}</td> -->
                <td>{{ position.tradeDate | date: 'shortDate' }}</td>
                <td class="whitespace-nowrap">
                  {{ position.shareInPercent | number: '.2-2' }} %
                </td>
                <td>{{ position.amount | number: '.2-2' }}</td>
                <td>{{ position.price | number: '.2-2' }}</td>
                <td>{{ position.priceBase | number: '.2-2' }}</td>
                <td>{{ position.pricePeak | number: '.2-2' }}</td>
                <!-- <td *ngIf="position.strategy">
            {{
            'limit-reports.review.strategy.' + position.strategy | transloco
            }}
          </td>
          <td *ngIf="!position.strategy"></td> -->
              </tr>
            }
            <tr class="info-table-summary-row">
              <td class="!font-bold">
                {{ 'limit-reports.review.SUM' | transloco }}
              </td>
              <td class="!font-bold"></td>
              <td class="whitespace-nowrap !font-bold">
                {{ overview.closedPositionReview.shareSum | number: '.2-2' }} %
              </td>
              <td class="!font-bold">
                {{ overview.closedPositionReview.amountSum | number: '.2-2' }}
              </td>
              <td class="!font-bold">
                {{
                  overview.closedPositionReview.priceAverage | number: '.2-2'
                }}
              </td>
              <td class="!font-bold"></td>
              <td class="!font-bold"></td>
              <!-- <td></td> -->
            </tr>
          </tbody>
        </table>
      </div>
      @if (showClosedPositionsAssessment) {
        <div
          class="closed-positions-assessment w-full xl:w-1/2 pl-[0px] xl:pl-2.5 print:w-1/2 print:pl-3 overflow-y-auto"
        >
          <h2>
            {{
              'limit-reports.review.CLOSED_SPREAD_POSITIONS_REVIEW_TITLE'
                | transloco
            }}
          </h2>
          <table class="table-layout">
            <thead>
              <tr>
                <!-- <th>
          {{ 'limit-reports.review.fields.STRATEGY' | transloco }}
        </th> -->
                <th></th>
                <th>
                  {{
                    'limit-reports.review.fields.AMOUNT_POSITIONS' | transloco
                  }}
                </th>
                <th>
                  {{ 'limit-reports.review.fields.AMOUNT' | transloco }} [MWh]
                </th>
                <th>
                  {{
                    'limit-reports.review.fields.SHARE_IN_PERCENT' | transloco
                  }}
                </th>
                <th>
                  {{ 'limit-reports.review.fields.PRICE' | transloco }} [€/MWh]
                </th>
                <th>
                  {{ 'limit-reports.review.fields.FULL_PRICE' | transloco }}
                  [€]
                </th>
                <th>
                  {{
                    'limit-reports.review.fields.BENCHMARK' | transloco
                  }}
                  [€/MWh]
                </th>
                <th>
                  {{
                    'limit-reports.review.fields.DIFFERENCE' | transloco
                  }}
                  [€/MWh]
                </th>
                <th>
                  {{ 'limit-reports.review.fields.DIFFERENCE' | transloco }} [%]
                </th>
              </tr>
            </thead>
            <tbody>
              @for (
                position of overview.closedPositionEvaluatedReview.positions;
                track position
              ) {
                <tr>
                  <!-- <td *ngIf="position.strategy">
          {{
          'limit-reports.review.strategy.' + position.strategy | transloco
          }}
        </td>
        <td *ngIf="!position.strategy"></td> -->
                  <td class="!font-normal"></td>
                  <td>{{ position.amountPositions }}</td>
                  <td>{{ position.amount | number: '.2-2' }}</td>
                  <td style="width: 80px" class="whitespace-nowrap">
                    {{ position.shareInPercent | number: '.2-2' }} %
                  </td>
                  <td>{{ position.price | number: '.2-2' }}</td>
                  <td>{{ position.fullPrice | number: '.2-2' }}</td>
                  <td>{{ position.benchmark | number: '.2-2' }}</td>
                  <td>{{ position.differencePrice | number: '.2-2' }}</td>
                  <td style="width: 100px" class="whitespace-nowrap">
                    {{ position.differencePercent | number: '.2-2' }} %
                  </td>
                </tr>
              }
              <tr class="info-table-summary-row">
                <td class="!font-bold">
                  {{ 'limit-reports.review.SUM' | transloco }}
                </td>
                <td class="!font-bold">
                  {{ overview.closedPositionEvaluatedReview.amountPositions }}
                </td>
                <td class="!font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.amount
                      | number: '.2-2'
                  }}
                </td>
                <td style="width: 80px" class="whitespace-nowrap !font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.shareInPercent
                      | number: '.2-2'
                  }}
                  %
                </td>
                <td class="!font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.price
                      | number: '.2-2'
                  }}
                </td>
                <td class="!font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.fullPrice
                      | number: '.2-2'
                  }}
                </td>
                <td class="!font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.benchmark
                      | number: '.2-2'
                  }}
                </td>
                <td class="!font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.differencePrice
                      | number: '.2-2'
                  }}
                </td>
                <td style="width: 100px" class="whitespace-nowrap !font-bold">
                  {{
                    overview.closedPositionEvaluatedReview.differencePercent
                      | number: '.2-2'
                  }}
                  %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
    <div class="flex flex-col xl:flex-row pb-5 print:flex-row">
      <div class="open-positions w-full xl:w-1/2 print:w-1/2">
        <h2>
          {{ 'limit-reports.review.OPEN_SPREAD_POSITIONS_TITLE' | transloco }}
        </h2>
        <table class="table-layout">
          <thead>
            <tr>
              <th>
                {{ 'limit-reports.review.fields.OPEN_PURCHASES' | transloco }}
              </th>
              <th>
                {{ 'limit-reports.review.fields.SHARE_IN_PERCENT' | transloco }}
              </th>
              <th>
                {{ 'limit-reports.review.fields.AMOUNT' | transloco }} [MWh]
              </th>
              <th>
                {{
                  'limit-reports.review.fields.CURRENT_MARKET_PRICE' | transloco
                }}
                [€/MWh]
              </th>
              <th>
                {{ 'limit-reports.review.fields.COMPLETE_PRICE' | transloco }}
                [€]
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="info-table-summary-row-bottom">
              <td class="!font-bold">
                {{ overview.openPositionsReview.openPurchases ? '>=1' : '0' }}
              </td>
              <td class="whitespace-nowrap !font-bold">
                {{
                  overview.openPositionsReview.shareInPercent | number: '.2-2'
                }}
                %
              </td>
              <td class="!font-bold">
                {{ overview.openPositionsReview.amount | number: '.2-2' }}
              </td>
              <td class="!font-bold">
                {{
                  overview.openPositionsReview.currentMarketPrice
                    | number: '.2-2'
                }}
              </td>
              <td class="!font-bold">
                {{
                  overview.openPositionsReview.completePrice | number: '.2-2'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="total w-full xl:w-1/2 pl-[0px] xl:pl-2.5 print:w-1/2 print:pl-2.5"
      >
        <h2>
          {{ 'limit-reports.review.SPREAD_TOTAL_TITLE' | transloco }}
        </h2>
        <table class="table-layout">
          <thead>
            <tr>
              <th>
                {{ 'limit-reports.review.fields.SHARE_IN_PERCENT' | transloco }}
              </th>
              <th>
                {{ 'limit-reports.review.fields.AMOUNT' | transloco }} [MWh]
              </th>
              <th>
                {{
                  'limit-reports.review.fields.CURRENT_MARKET_PRICE' | transloco
                }}
                [€/MWh]
              </th>
              <th>
                {{
                  'limit-reports.review.fields.FULL_MARKET_PRICE' | transloco
                }}
                [€]
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="info-table-summary-row-bottom">
              <td class="whitespace-nowrap !font-bold">
                {{
                  overview.allPositionsReview.shareInPercent | number: '.2-2'
                }}
                %
              </td>
              <td class="!font-bold">
                {{ overview.allPositionsReview.amount | number: '.2-2' }}
              </td>
              <td class="!font-bold">
                {{
                  overview.allPositionsReview.currentMarketPrice
                    | number: '.2-2'
                }}
              </td>
              <td class="!font-bold">
                {{ overview.allPositionsReview.fullPrice | number: '.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
import { LimitReportPowerReviewSpreadOverviewComponent } from './limit-report-review-spread-overview.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, MatProgressBarModule],
  declarations: [LimitReportPowerReviewSpreadOverviewComponent],
  exports: [LimitReportPowerReviewSpreadOverviewComponent],
})
export class LimitReportPowerReviewSpreadOverviewModule {}

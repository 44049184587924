import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
  inject,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  LimitReportReviewSettings,
  LimitReportStore,
} from '@backoffice-frontend/limit-report/domain';
import {
  LimitReportBenchmarkGraphModule,
  LimitReportEnergySourcePolicyChartModule,
  LimitReportIndexGraphModule,
  LimitReportLimitGraphModule,
  LimitReportPassiveStrategyGraphModule,
  LimitReportValueRiskGraphModule,
} from '@backoffice-frontend/limit-report/graphs/ui-graphs';
import { LimitReportTechIndReviewModule } from '@backoffice-frontend/limit-report/graphs/ui-technical-indicators-graphs';
import { DateHelper, PrintService, indicate } from '@clean-code/shared/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { faArchive } from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LimitReportReviewSettingsPowerService } from '../../services/limit-report-review.service';
import { LimitReportPowerReviewHknOverviewModule } from '../limit-report-review-hkn-overview/limit-report-review-hkn-overview.module';
import { LimitReportPowerReviewOverviewComponent } from '../limit-report-review-overview/limit-report-review-overview.component';
import { LimitReportPowerReviewSpreadOverviewModule } from '../limit-report-review-spread-overview/limit-report-review-spread-overview.module';

@Component({
  standalone: true,
  selector: 'backoffice-frontend-limit-report-power-review',
  templateUrl: './limit-report-review.component.html',
  styleUrls: ['./limit-report-review.component.scss'],
  imports: [
    CommonModule,
    MatProgressBarModule,

    ActionButtonModule,
    FormContainerModule,
    ProgressBarModule,

    LimitReportIndexGraphModule,
    LimitReportBenchmarkGraphModule,
    LimitReportLimitGraphModule,
    LimitReportValueRiskGraphModule,
    LimitReportTechIndReviewModule,
    LimitReportEnergySourcePolicyChartModule,
    LimitReportPassiveStrategyGraphModule,
    LimitReportPowerReviewOverviewComponent,
    LimitReportPowerReviewSpreadOverviewModule,
    LimitReportPowerReviewHknOverviewModule,
  ],
})
export class LimitReportPowerReviewComponent {
  @HostBinding('class') class = 'limit-report-review';

  @ViewChild('print', { read: ElementRef }) printContainer: ElementRef;

  public faArchive = faArchive;

  public isLoadingSettings$ = new BehaviorSubject<boolean>(false);
  public isLoadingOverview$ = new BehaviorSubject<boolean>(false);
  public isLoadingSpreadOverview$ = new BehaviorSubject<boolean>(false);
  public isLoadingHknOverview$ = new BehaviorSubject<boolean>(false);
  public isLoadingIndex$ = new BehaviorSubject<boolean>(false);
  public isLoadingSpreadIndex$ = new BehaviorSubject<boolean>(false);
  public isLoadingHknIndex$ = new BehaviorSubject<boolean>(false);
  public isLoadingBenchmark$ = new BehaviorSubject<boolean>(false);
  public isLoadingSpreadBenchmark$ = new BehaviorSubject<boolean>(false);
  public isLoadingHknBenchmark$ = new BehaviorSubject<boolean>(false);
  public isLoadingLimit$ = new BehaviorSubject<boolean>(false);
  public isLoadingSpreadLimit$ = new BehaviorSubject<boolean>(false);
  public isLoadingHknLimit$ = new BehaviorSubject<boolean>(false);
  public isLoadingValueRisk$ = new BehaviorSubject<boolean>(false);
  public isLoadingSpreadValueRisk$ = new BehaviorSubject<boolean>(false);
  public isLoadingHknValueRisk$ = new BehaviorSubject<boolean>(false);
  public isLoadingTechInd$ = new BehaviorSubject<boolean>(false);
  public isLoadingEnergySourcePolicy$ = new BehaviorSubject<boolean>(false);
  public isLoadingPassiveStrategy$ = new BehaviorSubject<boolean>(false);
  public isLoadingPassiveSpreadStrategy$ = new BehaviorSubject<boolean>(false);
  public isLoadingPassiveHknStrategy$ = new BehaviorSubject<boolean>(false);

  public updateDataTrigger$ = new BehaviorSubject<boolean>(false);

  public isLoading$ = combineLatest([
    this.isLoadingSettings$,
    this.isLoadingOverview$,
    this.isLoadingSpreadOverview$,
    this.isLoadingHknOverview$,
    this.isLoadingIndex$,
    this.isLoadingSpreadIndex$,
    this.isLoadingHknIndex$,
    this.isLoadingBenchmark$,
    this.isLoadingSpreadBenchmark$,
    this.isLoadingHknBenchmark$,
    this.isLoadingLimit$,
    this.isLoadingSpreadLimit$,
    this.isLoadingHknLimit$,
    this.isLoadingValueRisk$,
    this.isLoadingSpreadValueRisk$,
    this.isLoadingHknValueRisk$,
    this.isLoadingTechInd$,
    this.isLoadingEnergySourcePolicy$,
    this.isLoadingPassiveStrategy$,
    this.isLoadingPassiveSpreadStrategy$,
    this.isLoadingPassiveHknStrategy$,
  ]).pipe(map((values) => values.some((x) => x)));

  public settings: LimitReportReviewSettings;

  public showSpreadPositionsPageBreak = false;
  public showHknPositionsPageBreak = false;
  public showIndexPageBreak = false;
  public showSpreadIndexPageBreak = false;
  public showHknIndexPageBreak = false;
  public showBenchmarkPageBreak = false;
  public showSpreadBenchmarkPageBreak = false;
  public showHknBenchmarkPageBreak = false;
  public showPassivePageBreak = false;
  public showSpreadPassivePageBreak = false;
  public showHknPassivePageBreak = false;
  public showDynamicLimitPageBreak = false;
  public showSpreadDynamicLimitPageBreak = false;
  public showHknDynamicLimitPageBreak = false;
  public showDynamicVaRPageBreak = false;
  public showSpreadDynamicVaRPageBreak = false;
  public showHknDynamicVaRPageBreak = false;
  public showTechIndPageBreak = false;
  public showEnergySourcePageBreak = false;

  #limitReportStore = inject(LimitReportStore);
  #id$ = toObservable(this.#limitReportStore.id);

  public settings$ = combineLatest([this.#id$, this.updateDataTrigger$]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewShowSettings$(id)
        .pipe(indicate(this.isLoadingSettings$))
    ),
    tap((settings: LimitReportReviewSettings) => {
      this.settings = settings;
      this.setPageBreaks(settings);
    })
  );

  public overview$ = combineLatest([this.#id$, this.updateDataTrigger$]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewClosedPositions$(id)
        .pipe(indicate(this.isLoadingOverview$))
    )
  );

  public spreadOverview$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewClosedSpreadPositions$(id)
        .pipe(indicate(this.isLoadingSpreadOverview$))
    )
  );

  public hknOverview$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewClosedHknPositions$(id)
        .pipe(indicate(this.isLoadingHknOverview$))
    )
  );

  public indexChart$ = combineLatest([this.#id$, this.updateDataTrigger$]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewIndexChart$(id)
        .pipe(indicate(this.isLoadingIndex$))
    )
  );

  public spreadIndexChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewSpreadIndexChart$(id)
        .pipe(indicate(this.isLoadingSpreadIndex$))
    )
  );

  public hknIndexChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewHknIndexChart$(id)
        .pipe(indicate(this.isLoadingHknIndex$))
    )
  );

  public benchmarkChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewBenchmarkChart$(id)
        .pipe(indicate(this.isLoadingBenchmark$))
    )
  );

  public spreadBenchmarkChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewSpreadBenchmarkChart$(id)
        .pipe(indicate(this.isLoadingSpreadBenchmark$))
    )
  );

  public hknBenchmarkChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewHknBenchmarkChart$(id)
        .pipe(indicate(this.isLoadingHknBenchmark$))
    )
  );

  public limitChart$ = combineLatest([this.#id$, this.updateDataTrigger$]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicLimitChart$(id)
        .pipe(indicate(this.isLoadingLimit$))
    )
  );

  public spreadLimitChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicLimitSpreadChart$(id)
        .pipe(indicate(this.isLoadingSpreadLimit$))
    )
  );

  public hknLimitChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicLimitHknChart$(id)
        .pipe(indicate(this.isLoadingHknLimit$))
    )
  );

  public valueRiskChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicValueAtRiskChart$(id)
        .pipe(indicate(this.isLoadingValueRisk$))
    )
  );

  public spreadValueRiskChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicValueAtRiskSpreadChart$(id)
        .pipe(indicate(this.isLoadingSpreadValueRisk$))
    )
  );

  public hknValueRiskChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewDynamicValueAtRiskHknChart$(id)
        .pipe(indicate(this.isLoadingHknValueRisk$))
    )
  );

  public techIndChart$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewTechnicalIndicatorChart$(id)
        .pipe(indicate(this.isLoadingTechInd$))
    )
  );

  public energySourcePolicy$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewEnergySourcingPolicyChart$(id)
        .pipe(indicate(this.isLoadingEnergySourcePolicy$))
    )
  );

  public passiveStrategy$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewPassiveStrategyChart$(id)
        .pipe(indicate(this.isLoadingPassiveStrategy$))
    )
  );

  public spreadPassiveStrategy$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewSpreadPassiveStrategyChart$(id)
        .pipe(indicate(this.isLoadingPassiveSpreadStrategy$))
    )
  );

  public hknPassiveStrategy$ = combineLatest([
    this.#id$,
    this.updateDataTrigger$,
  ]).pipe(
    filter(([id, _]) => !!id),
    switchMap(([id, _]) =>
      this.limitReportReviewSettingsPowerService
        .limitReportReviewHknPassiveStrategyChart$(id)
        .pipe(indicate(this.isLoadingPassiveHknStrategy$))
    )
  );

  constructor(
    private limitReportReviewSettingsPowerService: LimitReportReviewSettingsPowerService,
    private printService: PrintService
  ) {}

  public async exportToPdf() {
    const name =
      'LimitReport_' +
      this.settings.companyName +
      '_' +
      this.settings.name +
      '_' +
      this.settings.limitReportName +
      '_' +
      DateHelper.toDateTimeString(new Date(Date.now()));

    const header = `
    <div class="flex flex-row space-x-2">
    <fa-icon class="ng-fa-icon power-icon ng-star-inserted" style="margin-bottom: 0.5rem;" ng-reflect-icon="[object Object]"><svg role="img" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plug" class="svg-inline--fa fa-plug" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96h32V16zm160 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v96h32V16zM16 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H32v64c0 83 63.1 151.2 144 159.2V496c0 8.8 7.2 16 16 16s16-7.2 16-16V399.2c80.9-8 144-76.2 144-159.2V176h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H352 320 64 32 16zM192 368c-70.7 0-128-57.3-128-128V176H320v64c0 70.7-57.3 128-128 128z"></path></svg></fa-icon>
    <h1 class="font-bold leading-8 text-gray-900 dark:text-primary-color text-3xl truncate">${this.settings.limitReportName}</h1> </div>`;
    this.printService.print(name, this.printContainer, header);
  }

  //todo: build a dependency tree! Also in Frontoffice!
  private setPageBreaks(settings: LimitReportReviewSettings) {
    if (settings.showPositions) {
      this.showSpreadPositionsPageBreak = true;
      this.showHknPositionsPageBreak = true;
      this.showIndexPageBreak = true;
      this.showSpreadIndexPageBreak = true;
      this.showHknIndexPageBreak = true;
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showSpreadPositions) {
      this.showHknPositionsPageBreak = true;
      this.showIndexPageBreak = true;
      this.showSpreadIndexPageBreak = true;
      this.showHknIndexPageBreak = true;
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showHknPositions) {
      this.showIndexPageBreak = true;
      this.showSpreadIndexPageBreak = true;
      this.showHknIndexPageBreak = true;
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showIndex) {
      this.showSpreadIndexPageBreak = true;
      this.showHknIndexPageBreak = true;
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showSpreadIndex) {
      this.showHknIndexPageBreak = true;
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showHknIndex) {
      this.showBenchmarkPageBreak = true;
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showBenchmark) {
      this.showSpreadBenchmarkPageBreak = true;
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showSpreadBenchmark) {
      this.showHknBenchmarkPageBreak = true;
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showHknBenchmark) {
      this.showPassivePageBreak = true;
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showPassiveStrategy) {
      this.showSpreadPassivePageBreak = true;
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showPassiveStrategySpread) {
      this.showHknPassivePageBreak = true;
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showPassiveStrategyHkn) {
      this.showDynamicLimitPageBreak = true;
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicLimit) {
      this.showSpreadDynamicLimitPageBreak = true;
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicLimitSpread) {
      this.showHknDynamicLimitPageBreak = true;
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicLimitHkn) {
      this.showDynamicVaRPageBreak = true;
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicVaR) {
      this.showSpreadDynamicVaRPageBreak = true;
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicVaRSpread) {
      this.showHknDynamicVaRPageBreak = true;
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showDynamicVaRHkn) {
      this.showTechIndPageBreak = true;
      this.showEnergySourcePageBreak = true;
    } else if (settings.showTecIndOverallStrategy) {
      this.showEnergySourcePageBreak = true;
    } else if (settings.showEnergySourcingPolicy) {
      // continue, if new charts are included
    }
  }

  public reorderedPassive() {
    this.updateDataTrigger$.next(true);
  }
}

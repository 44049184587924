<div class="flex flex-row justify-end">
  <cc-action-button
    displayText="common.export.PDF"
    tooltip="common.export.PDF_TOOLTIP"
    [disabledTooltip]="false"
    class="button-align pr-3 print:!hidden"
    [disabled]="isLoading$ | async"
    [icon]="faArchive"
    form-buttons
    (click)="exportToPdf()"
  ></cc-action-button>
</div>

<cc-progress-bar [isLoading]="isLoadingSettings$ | async"></cc-progress-bar>

<cc-form-container>
  <div class="print:!overflow-visible print:w-full" #print>
    @if (settings$ | async) {
      @if (settings.showPositions) {
        <backoffice-frontend-limit-report-review-power-overview
          [showClosedPositionsAssessment]="
            settings.showClosedPositionsAssessment
          "
          [data]="overview$ | async"
          [isLoading]="isLoadingOverview$ | async"
          >
        </backoffice-frontend-limit-report-review-power-overview>
      }
      @if (settings.showSpreadPositions) {
        @if (showSpreadPositionsPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-review-spread-power-overview
          [showClosedPositionsAssessment]="
            settings.showClosedPositionsAssessment
          "
          [data]="spreadOverview$ | async"
          [isLoading]="isLoadingSpreadOverview$ | async"
          >
        </backoffice-frontend-limit-report-review-spread-power-overview>
      }
      @if (settings.showHknPositions) {
        @if (showHknPositionsPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-review-hkn-power-overview
          [showClosedPositionsAssessment]="
            settings.showClosedPositionsAssessment
          "
          [data]="hknOverview$ | async"
          [isLoading]="isLoadingHknOverview$ | async"
          >
        </backoffice-frontend-limit-report-review-hkn-power-overview>
      }
      @if (settings.showIndex) {
        @if (showIndexPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-index-graph
          [isLoading]="isLoadingIndex$ | async"
          [unit]="settings.unit"
          [showPurchases]="settings.showPurchase"
          [data]="indexChart$ | async"
          [indexName]="settings.name"
          >
        </backoffice-frontend-limit-report-index-graph>
      }
      @if (settings.showSpreadIndex) {
        @if (showSpreadIndexPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-index-graph
          [isLoading]="isLoadingSpreadIndex$ | async"
          [unit]="settings.unit"
          [showPurchases]="settings.showPurchase"
          [data]="spreadIndexChart$ | async"
          [indexName]="settings.nameSpread"
          >
        </backoffice-frontend-limit-report-index-graph>
      }
      @if (settings.showHknIndex) {
        @if (showHknIndexPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-index-graph
          [isLoading]="isLoadingHknIndex$ | async"
          [unit]="settings.unit"
          [showPurchases]="settings.showPurchase"
          [data]="hknIndexChart$ | async"
          [indexName]="settings.nameHkn"
          [showHknClosingTimeMessage]="true"
          >
        </backoffice-frontend-limit-report-index-graph>
      }
      @if (settings.showBenchmark) {
        @if (showBenchmarkPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-benchmark-graph
          [isLoading]="isLoadingBenchmark$ | async"
          [data]="benchmarkChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          >
        </backoffice-frontend-limit-report-benchmark-graph>
      }
      @if (settings.showSpreadBenchmark) {
        @if (showSpreadBenchmarkPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-benchmark-graph
          [isLoading]="isLoadingSpreadBenchmark$ | async"
          [data]="spreadBenchmarkChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="'limit-reports.review.SPREAD_BENCHMARK_TABLE_TITLE'"
          >
        </backoffice-frontend-limit-report-benchmark-graph>
      }
      @if (settings.showHknBenchmark) {
        @if (showHknBenchmarkPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-benchmark-graph
          [isLoading]="isLoadingHknBenchmark$ | async"
          [data]="hknBenchmarkChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="'limit-reports.review.HKN_BENCHMARK_TABLE_TITLE'"
          >
        </backoffice-frontend-limit-report-benchmark-graph>
      }
      @if (settings.showPassiveStrategy) {
        @if (showPassivePageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-passive-strategy-graph
          [isLoading]="isLoadingPassiveStrategy$ | async"
          [energySource]="'POWER'"
          [productName]="settings.name"
          [data]="passiveStrategy$ | async"
          [unit]="settings.unit"
          (reorderedPassive)="reorderedPassive()"
          >
        </backoffice-frontend-limit-report-passive-strategy-graph>
      }
      @if (settings.showPassiveStrategySpread) {
        @if (showSpreadPassivePageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-passive-strategy-graph
          [isLoading]="isLoadingPassiveSpreadStrategy$ | async"
          [energySource]="'POWER'"
          [productName]="settings.name"
          [data]="spreadPassiveStrategy$ | async"
          [unit]="settings.unit"
          [isSpread]="true"
          (reorderedPassive)="reorderedPassive()"
          [title]="'limit-reports.review.PASSIVE_SPREAD_STRATEGY_TITLE'"
          >
        </backoffice-frontend-limit-report-passive-strategy-graph>
      }
      @if (settings.showPassiveStrategyHkn) {
        @if (showHknPassivePageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-passive-strategy-graph
          [isLoading]="isLoadingPassiveHknStrategy$ | async"
          [energySource]="'POWER'"
          [productName]="settings.name"
          [data]="hknPassiveStrategy$ | async"
          [unit]="settings.unit"
          [isHkn]="true"
          (reorderedPassive)="reorderedPassive()"
          [title]="'limit-reports.review.PASSIVE_HKN_STRATEGY_TITLE'"
          >
        </backoffice-frontend-limit-report-passive-strategy-graph>
      }
      @if (settings.showDynamicLimit) {
        @if (showDynamicLimitPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-limit-graph
          [isLoading]="isLoadingLimit$ | async"
          [data]="limitChart$ | async"
          [signalMode]="settings.dynamicLimitSignalGenerationMode"
          [unit]="settings.unit"
          [indexName]="settings.name"
          >
        </backoffice-frontend-limit-report-limit-graph>
      }
      @if (settings.showDynamicLimitSpread) {
        @if (showSpreadDynamicLimitPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-limit-graph
          [isLoading]="isLoadingSpreadLimit$ | async"
          [data]="spreadLimitChart$ | async"
          [signalMode]="settings.dynamicLimitSignalGenerationModeSpread"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="'limit-reports.review.DYNAMIC_LIMIT_SPREAD_VALUE_TITLE'"
          >
        </backoffice-frontend-limit-report-limit-graph>
      }
      @if (settings.showDynamicLimitHkn) {
        @if (showHknDynamicLimitPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-limit-graph
          [isLoading]="isLoadingHknLimit$ | async"
          [data]="hknLimitChart$ | async"
          [signalMode]="settings.dynamicLimitSignalGenerationModeHkn"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="'limit-reports.review.DYNAMIC_LIMIT_HKN_VALUE_TITLE'"
          >
        </backoffice-frontend-limit-report-limit-graph>
      }
      @if (settings.showDynamicVaR) {
        @if (showDynamicVaRPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-value-risk-graph
          [isLoading]="isLoadingValueRisk$ | async"
          [signalMode]="settings.dynamicValueAtRiskSignalGenerationMode"
          [data]="valueRiskChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          >
        </backoffice-frontend-limit-report-value-risk-graph>
      }
      @if (settings.showDynamicVaRSpread) {
        @if (showSpreadDynamicVaRPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-value-risk-graph
          [isLoading]="isLoadingSpreadValueRisk$ | async"
          [signalMode]="settings.dynamicValueAtRiskSignalGenerationModeSpread"
          [data]="spreadValueRiskChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="
            'limit-reports.review.DYNAMIC_LIMIT_SPREAD_VALUE_AT_RISK_TITLE'
          "
          >
        </backoffice-frontend-limit-report-value-risk-graph>
      }
      @if (settings.showDynamicVaRHkn) {
        @if (showHknDynamicVaRPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-value-risk-graph
          [isLoading]="isLoadingHknValueRisk$ | async"
          [signalMode]="settings.dynamicValueAtRiskSignalGenerationModeHkn"
          [data]="hknValueRiskChart$ | async"
          [unit]="settings.unit"
          [indexName]="settings.name"
          [title]="'limit-reports.review.DYNAMIC_LIMIT_HKN_VALUE_AT_RISK_TITLE'"
          >
        </backoffice-frontend-limit-report-value-risk-graph>
      }
      @if (settings.showTecIndOverallStrategy) {
        @if (showTechIndPageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-tech-ind-review
          [isLoading]="isLoadingTechInd$ | async"
          [signalMode]="settings.technicalIndicatorSignalGenerationMode"
          [productName]="settings.name"
          [data]="techIndChart$ | async"
          [unit]="settings.unit"
          [settings]="settings"
          >
        </backoffice-frontend-limit-report-tech-ind-review>
      }
      @if (settings.showEnergySourcingPolicy) {
        @if (showEnergySourcePageBreak) {
          <div class="pagebreak"></div>
        }
        <backoffice-frontend-limit-report-energy-source-policy-graph
          [isLoading]="isLoadingEnergySourcePolicy$ | async"
          [data]="energySourcePolicy$ | async"
          [unit]="settings.unit"
          >
        </backoffice-frontend-limit-report-energy-source-policy-graph>
      }
    }
  </div>
</cc-form-container>

import { Component } from '@angular/core';
import { LimitReportReviewOverviewBaseComponent } from '@backoffice-frontend/limit-report/domain';

@Component({
  selector: 'backoffice-frontend-limit-report-review-spread-power-overview',
  templateUrl: './limit-report-review-spread-overview.component.html',
  styleUrls: ['./limit-report-review-spread-overview.component.scss'],
})
export class LimitReportPowerReviewSpreadOverviewComponent extends LimitReportReviewOverviewBaseComponent {
  constructor() {
    super();
  }
}

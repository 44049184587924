import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
import { LimitReportPowerReviewHknOverviewComponent } from './limit-report-review-hkn-overview.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, MatProgressBarModule],
  declarations: [LimitReportPowerReviewHknOverviewComponent],
  exports: [LimitReportPowerReviewHknOverviewComponent],
})
export class LimitReportPowerReviewHknOverviewModule {}
